import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
//import SuccessAlert from "../../alerts/SuccessAlert";
//import ErrorAlert from "../../alerts/ErrorAlert";

import emailjs from "@emailjs/browser";

export const QuestionsForm = () => {
    const form = useRef();
    const {
        register,
        handleSubmit,
        //formState: { errors, isSubmitted },
    } = useForm({
        mode: "all",
        // defaultValues: {
        //     email: "xd@email.com",
        //     question: "bs fjklbaofh apb bnaposdů fh.afjbpůo ilhks nbu",
        // },
    });
    const [buttonTimeout, setButtonTimeout] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    // let alertTimeout; // -----------------------------ALERT
    // const handleTimeout = () => {
    //     alertTimeout = setTimeout(function () {
    //         setIsFormSubmitted(false);
    //         setIsFormValid(false);
    //     }, 6000);
    // };
    useEffect(() => {
        // if (isFormSubmitted === true) {
        //     handleTimeout();
        // }
    }, [isFormSubmitted]);
    // const renderAlert = () => {
    //     if (isFormSubmitted === true) {
    //         if (isFormValid === true) {
    //             return <SuccessAlert onClick={closeAlert} />;
    //         } else if (isFormValid === false) {
    //             return <ErrorAlert onClick={closeAlert} />;
    //         }
    //     }
    // };

    const reloadPage = () => {
        window.location.reload(); //??????????????????????
    }

    const resetFormState = () => {
        setIsFormValid(false);
        setIsFormSubmitted(false);
        setErrorMessage(false);
        setButtonTimeout(false);
        reloadPage();
    };

    const renderMessage = () => {
        if (isFormSubmitted === true) {
            if (isFormValid === true) {
                return (
                    <div className="form__form-submitted__container">
                        <h3 className="form__form-submitted__message">Dotaz byl úspěšně odeslán.</h3>
                        <button className="submit-button submit-button--light form__form-submitted__button" onClick={() => resetFormState()}>Zpět na formulář</button>
                    </div>
                )
            } else if (isFormValid === false) {
                return (
                    <div className="form__form-submitted__container">   
                        <h3 className="form__form-submitted__message">Při odesílání nastala chyba, zkuste prosím formulář odeslat znovu. <br/  >Případně svůj dotaz odešlete přímo na naší emailovou adresu: <a
                        href="mailto:havranizatoka@gmail.com"
                        alt="Odkaz na Email"
                        target="_blank"
                        rel="noopener"
                    >havranizatoka@gmail.com</a></h3>
                        <button className="submit-button submit-button--light form__form-submitted__button" onClick={() =>  resetFormState()}>Zpět na formulář</button>
                    </div>
                );
            }
        }
    }

    // const closeAlert = () => {
    //     clearTimeout(alertTimeout);
    //     setIsFormSubmitted(false);
    //     setIsFormValid(false);
    // };

    const onSubmit = (data, e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                // "service_qv9twlc", //udaje na osobni ucet (test)
                // "template_2c3eeex",
                // form.current,
                // "Vgf9Bs67OxlLiFLWG"

                
                "service_7tsk2ls",
                "template_2vddieb",
                form.current,
                "9ofuWKU650zVhtJfP"
            )
            .then(
                (result) => {
                    setIsFormSubmitted(true);
                    setIsFormValid(true);
                    //console.log("success", result);
                },
                (error) => {
                    setIsFormSubmitted(true);
                    //console.log("error", error);
                }
            );
    };
    const onError = (errors, e) => {
        e.preventDefault();
        setErrorMessage(true);
        //console.log("error", errors);
    };

    return (
        <section className="for-parents-page__question-section">
            {/* {renderAlert()} */}
            {isFormSubmitted === true ? 
            renderMessage()
            :
            <>
            <h2 className="for-parents-page__question-title">
                Máte dotaz? Napište nám!
            </h2>
            <form
                ref={form}
                onSubmit={handleSubmit(onSubmit, onError)}
                className="for-parents-page__question-form"
            >
                {errorMessage === true ? (
                    <span className="for-parents-page__question-form-error">
                        Zkontrolujte si prosím, že jsou všechny informace
                        vyplňeny správně!
                    </span>
                ) : null}
                <div>
                    <label
                        htmlFor="question-email"
                        className="for-parents-page__question-label"
                    >
                        Váš email{" "}
                    </label>
                    <input
                        id="question-email"
                        type="email"
                        className="for-parents-page__question-email"
                        {...register("email", {
                            required: true,
                            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, //  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/   ---- \.
                        })}
                    ></input>
                </div>
                <textarea
                    className="for-parents-page__question-textarea"
                    {...register("dotaz", { required: true })}
                ></textarea>
                 {
                buttonTimeout === true ? 
                <button disabled className="submit-button submit-button--light">Odeslat</button>
                :
                <button className="submit-button submit-button--light">Odeslat</button>
                }
            </form> </>}
        </section>
    );
};

export default QuestionsForm;
