import React from "react";

const regex = new RegExp(/(^[^.]*)/g);

export const OptimizedImage = ({ src }) => {
    const alt = src.split("/").pop().match(regex).join();
    return (
        // <picture className="picture">
        //     <source type="image/webp" src={src} />
        <img
            className="image"
            src={src}
            loading="lazy"
            alt={alt}
            decoding="async"
            fetchpriority="high"
        />
        // </picture>
    );
};
export default OptimizedImage;
