import React, { useEffect } from "react";
import "./preschool-page.scss";
import havran from "./images/havran.jpeg";
import stan from "./images/stan.jpeg";

export const PreschoolPage = () => {
    useEffect(() => {
        document.title = "Letní dětský tábor Osada Na Havraní zátoce 2024";
    }, []);
    return (
        <div className="preschool-page page">
            <h1 className="page__title">Tábor pro předškoláky</h1>
            <div className="preschool-page__body page__body">
                {/* <section className="for-parents-page__section">
                    <h2 className="for-parents-page__section-title">Cena</h2>
                    <ul>
                        <li>
                            <b>3.000 Kč.</b>
                        </li>
                        <li>
                            Zahrnuje: Dopravu autobusem z Berouna na tábor a
                            zpět, stravu, ubytování, materiál na činnost a
                            hry...
                        </li>
                    </ul>
                </section> */}
                <section className="preschool-page__section">
                    <p className="preschool-page__paragraph">Tábor Osada Na Havraní zátoce funguje <b>téměř 30 let</b> a děti se nám nadšeny každoročně vrací zpět. Rádi bychom rozšířili naše kapacity o oddíl <b>nejmenších táborníků</b> ve <b>věku 5-6 let</b>, který bude plně přizpůsoben jejich věku, schopnostem a potřebám.</p>
                    <h2 className="preschool-page__h2 preschool-page__h2--right section-title">První tábor? Není problém!</h2>
                    <img src={stan} alt="Malovaný obrázek táborového stanu" className="preschool-page__image"/>
                    <p className="preschool-page__paragraph">Děti si na táboře najdou nové kamarády a naučí se samostatnosti, která jim pomůže i v první třídě. Při zkoušení nových věcí, ať už vázání základních uzlů či tkaniček na botách, učení se šifer třeba jen v podobě napsání svého jména, nebo zpívání táborových písniček si užijí spoustu zábavy! </p>
                    <p className="preschool-page__paragraph">Zapojí se dokonce do celotáborové hry a dalších aktivit s ostatními táborníky (s menší dopomocí vedoucích).</p>
                    <p className="preschool-page__paragraph">O naše malé táborníky se postarají tři <b>zkušené vedoucí</b>. Jedna s maturitou z pedagogiky a praxemi v mateřské školce, druhá tou dobou zdravotnice a třetí pravidelně doučuje děti, větší i ty menší. Rádi pomůžeme se sebeobsluhou, ať v podobě čistění zubů, oblékání se nebo krájení buřtů, zpívání ukolébavek na dobrou noc nebo čtení pohádek.</p>
                    <p className="preschool-page__paragraph">Uvědomujeme si, že týden na táboře bude dost možná prvním týdnem odloučení dětí od milujících rodičů. Nebojte se proto s sebou vzít své kamarády a přijeďte společně! Děti budou mít větší jistotu a pocit bezpečí, když nebudou samy. </p>
                </section>
                <section className="preschool-page__section preschool-page__section--two">
                    <h2 className="preschool-page__h2 preschool-page__h2--left section-title">Důležité informace</h2>
                    <img src={havran} alt="Malovaný obrázek havrana" className="preschool-page__image preschool-page__image--right" />
                    <p className="preschool-page__paragraph">Tábor pro předškoláky je připraven, na rozdíl od 14 denního táboru pro starší, <b>pouze na týden</b>. Víme, že dva týdny by byly těžké pro děti i pro rodiče. Bohužel to ale znamená, že děti si z tábora budete muset odvézt sami. Věříme, že to pro vás nebude stěžejní problém a rádi poznáte prostředí, ve kterém vaše ratolesti týden pobývaly. Cesta tam je zajištěna autobusem z Berouna společně se staršími táborníky.</p>
                    <p className="preschool-page__paragraph">Nemusíte se tedy bát, že by o vaše nejmilejší nebylo dobře postaráno.</p>
                    <p className="preschool-page__paragraph">Kdyby nastal jakýkoliv problém nebo stesk ovládl i ty nejstatečnější, je samozřejmostí, že vás budeme kontaktovat a situaci společně operativně vyřešíme.</p>
                    <p className="preschool-page__paragraph"><b>Cena týdenního tábora je 3 000Kč</b>, včetně dopravy na místo, vyvážené stravy, kterou s láskou připravují naše zkušené kuchařky, ubytování ve stanech s podsadou a sprch. Snažíme se o co nejvstřícnější cenu, aby k nám mohl jet téměř kdokoliv. Všichni vedoucí jsme dobrovolníci a organizujeme tábor z lásky k dětem a k přírodě.</p>
                    <p className="preschool-page__paragraph">Platba proběhne ve stejné podobě jako u větších oddílů, <b>stačí v <a className="preschool-page__application-link" href="/prihlaska">přihlášce</a> vybrat “Tábor pro předškoláky”</b> a informace Vám přijdou osobně emailem. V případě malého zájmu se týdenní tábor konat nebude. To Vám ale ovšem dáme včas vědět a celou částku peněz vrátíme zpět.</p>
                </section>
                <strong className="preschool-page__greating">Budeme se na Vás těšit!</strong>
            </div>
        </div>
    )
}