import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/pages/special/ErrorFallBack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
    </ErrorBoundary>
    //</React.StrictMode>
);
