import React from "react";

export const Video = ({ video }) => (
    <iframe
        src={video.src}
        className="responsive-iframe"
        //frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={video.title}
        fetchpriority="high"
    />
);

export default Video;
