import React from "react";

export const OptimizedAlbumImage = ({ src, onClick }) => {
    const handleOnCLick = onClick;
    return (
        <img
            className="gallery-page__album-image"
            src={src}
            //loading="lazy"
            alt="sldfkjsdlf"
            decoding="sync"
            onClick={() => handleOnCLick}
            fetchpriority="high"
        />
    );
};
export default OptimizedAlbumImage;
