import React, { useEffect } from "react";
import ApplicationForm from "./form/ApplicationForm";
import "./application-page.scss";
export const ApplicationPage = () => {
    useEffect(() => {
        document.title =
            "Přihláška | Letní dětský tábor Osada Na Havraní zátoce 2024";
    }, []);
    return (
        <div className="application-page page">
            <h2 className="page__title">Předběžná přihláška</h2>
            {/* <p className="application-page__additional-information">
                *Po odeslání předběžné přihlášky Vás budeme kontaktovat a
                následně zašleme přihlášku závaznou.
            </p> */}
            {/* <EmailContactForm /> */}
            <section className="form-section">
                <ApplicationForm />
            </section>
        </div>
    );
};
export default ApplicationPage;
