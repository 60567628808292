import React from "react";
import OptimizedImageForGallery from "../../optimized-image/OptimizedImageForGallery";
import "./gallery-page.scss";

export const GalleryGrid = ({
    photos,
    currentAlbum,
    currentPhoto,
    setCurrentPhoto,
    photosLoading,
}) => {
    const selectPhoto = (photo) => {
        setCurrentPhoto(photo);
    };
    return (
        <div className="gallery-grid">
            {photos.map((photo, index) => {
                return (
                    <OptimizedImageForGallery
                        key={index}
                        photo={photo}
                        onClick={() => selectPhoto(photo)}
                        //current={photo === currentPhoto}
                        index={index}
                        year={currentAlbum}
                        photosLoading={photosLoading}
                    />
                );
            })}
        </div>
    );
};
