import React, { useEffect, useState } from "react";
import { GalleryGrid } from "./GalleryGrid";
import Album from "./Album";
import { ArrowCircleLeft, ArrowCircleRight, CloseCircle } from "iconsax-react";
import { albums } from "./albums";
// import Video from "./Video";
// import { videos } from "./videos";
import useSwipe from "../../../hooks/useSwipe";
import abortPromise from "../../../hooks/abortPromise";

import Data2019 from "./dimensions-2019.json";
import Data2020 from "./dimensions-2020.json";
import Data2021 from "./dimensions-2021.json";
import Data2022 from "./dimensions-2022.json";
import Data2023 from "./dimensions-2023.json";

export const GalleryPage = () => {
    const [photosLoading, setPhotosLoading] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState(null);
    const [currentAlbum, setCurrentAlbum] = useState("2023");
    const [photos, setPhotos] = useState(Data2023);

    const photosArraySwitch = (folder) => {
        setCurrentAlbum(folder);
        switch (folder) {
            case "2023":
                setPhotos(Data2023);
                break;
            case "2022":
                setPhotos(Data2022);
                break;
            case "2021":
                setPhotos(Data2021);
                break;
            case "2020":
                setPhotos(Data2020);
                break;
            case "2019":
                setPhotos(Data2019);
                break;
            default:
                setPhotos(Data2023);
                break;
        }
    };

    useEffect(() => {
        document.title =
            "Galerie | Letní dětský tábor Osada Na Havraní zátoce 2024";
    }, []);

    const handleAlbumChange = (year) => {
        const getPhotos = () => {
            photosArraySwitch(year);
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 0);
            });
        };
        setPhotosLoading(true);
        const p = getPhotos();
        const { promise, cancel } = abortPromise(p); // converting original promise to cancellable promise
        promise.then((d) => {
            setPhotosLoading(false);
        });
        return cancel;
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            closeOverlay();
        }
    };
    const closeOverlay = () => {
        setCurrentPhoto(null);
        window.history.back();
    };
    const previousPhoto = () => {
        setCurrentPhoto(photos[photos.indexOf(currentPhoto) - 1]);
    };
    const nextPhoto = () => {
        setCurrentPhoto(photos[photos.indexOf(currentPhoto) + 1]);
    };

    useEffect(() => {
        if (currentPhoto) {
            window.history.pushState({}, "");
        }
    }, [currentPhoto]);

    window.onpopstate = function () {
        setCurrentPhoto(null);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Escape") {
            closeOverlay();
        } else if (e.key === "ArrowRight") {
            nextPhoto();
        } else if (e.key === "ArrowLeft") {
            previousPhoto();
        }
    };
    const [handleTouchStart, handleSwipe] = useSwipe(previousPhoto, nextPhoto);
    return (
        <div
            className="gallery-page page"
            onKeyDown={currentPhoto ? (e) => handleKeyPress(e) : null}
            tabIndex="0"
        >
            {currentPhoto ? (
                <div
                    className="gallery-page__overlay"
                    onClick={(e) => handleOverlayClick(e)}
                >
                    <div className="gallery-page__overlay-image-container">
                        <img
                            className="gallery-image gallery-image--current"
                            src={require(`./${currentAlbum}/${currentPhoto.file}`)}
                            loading="lazy"
                            alt=""
                            decoding="sync"
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleSwipe}
                        />
                        <span
                            className="gallery-page__close-overlay-button"
                            onClick={() => closeOverlay()}
                        >
                            <CloseCircle size="40" color="var(--white)" />
                        </span>
                        <span
                            className="gallery-page__change-image-panel gallery-page__change-image-panel--left"
                            onClick={() => previousPhoto()}
                        >
                            <span className="gallery-page__change-image-button gallery-page__change-image-button--previous">
                                <ArrowCircleLeft
                                    size="50"
                                    color="var(--white)"
                                />
                            </span>
                        </span>
                        <span
                            className="gallery-page__change-image-panel gallery-page__change-image-panel--right"
                            onClick={() => nextPhoto()}
                        >
                            <span className="gallery-page__change-image-button gallery-page__change-image-button--next">
                                <ArrowCircleRight
                                    size="50"
                                    color="var(--white)"
                                />
                            </span>
                        </span>
                    </div>
                </div>
            ) : null}

            <h2 className="page__title">Galerie</h2>
            {/* <FetchFromGoogleSheets /> */}
            <div className="gallery-page__album-container">
                {albums.map((photo, index) => {
                    return (
                        <Album
                            key={index}
                            photo={photo}
                            onClick={(e) => handleAlbumChange(e)}
                            selected={currentAlbum === photo.year}
                        />
                    );
                })}
            </div>
            {/* {videos.map((video, index) => {
                return video.year === currentAlbum ? (
                    <div key={index} className="gallery-page__video-container">
                        <Video key={index} video={video} />
                    </div>
                ) : null;
            })} */}
            {
                <GalleryGrid
                    currentPhoto={currentPhoto}
                    setCurrentPhoto={setCurrentPhoto}
                    photos={photos}
                    currentAlbum={currentAlbum}
                    photosLoading={photosLoading}
                />
            }
        </div>
    );
};
export default GalleryPage;
