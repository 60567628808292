import React from "react";
export const OptimizedImageForGallery = ({
    photo,
    year,
    onClick,
    index,
    photosLoading,
}) => {
    const handleOnCLick = onClick;
    const picture = require(`../pages/gallery-page/${year}/${photo.file}`);
    return (
        <div
            className="gallery-page__image-skeleton"
            key={index}
            style={{
                width: "100%",
                aspectRatio: photo.width / photo.height,
            }}
        >
            {photosLoading === false ? (
                <img
                    className="gallery-image"
                    src={picture}
                    loading="lazy"
                    alt=""
                    decoding="async"
                    onClick={() => handleOnCLick()}
                    fetchpriority="high"
                    style={{
                        width: "100%",
                        aspectRatio: photo.width / photo.height,
                    }}
                />
            ) : null}
        </div>
    );
};
export default OptimizedImageForGallery;
