import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./mobile-menu.scss";
import { Sms, CallCalling, Facebook } from "iconsax-react";

export const MobileMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleMenu = () => setIsMenuOpen(!isMenuOpen);
    const handleMenuClassName = () => {
        return isMenuOpen
            ? "navbar__hamburger  navbar__hamburger--open"
            : "navbar__hamburger";
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            handleMenu();
        }
    };
    return (
        <div className="navbar__menu">
            <div className={handleMenuClassName()} onClick={() => handleMenu()}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div
                className="navbar__menu-container"
                onClick={(e) => handleOverlayClick(e)}
            >
                <div className="navbar__menu-navigation">
                    <Link
                        className="navbar__menu-link navbar__menu-link--pages"
                        to="/"
                        onClick={() => handleMenu()}
                    >
                        Domů
                    </Link>
                    <Link
                        className="navbar__menu-link navbar__menu-link--pages"
                        to="/pro-rodice"
                        onClick={() => handleMenu()}
                    >
                        Pro rodiče
                    </Link>
                    <Link
                        className="navbar__menu-link navbar__menu-link--pages"
                        to="/tabor-pro-predskolaky"
                        onClick={() => handleMenu()}
                    >
                        Tábor pro předškoláky
                    </Link>
                    <Link
                        className="navbar__menu-link navbar__menu-link--pages"
                        to="/galerie"
                        onClick={() => handleMenu()}
                    >
                        Galerie
                    </Link>
                    <Link
                        className="navbar__menu-link navbar__menu-link--pages"
                        to="/prihlaska"
                        onClick={() => handleMenu()}
                    >
                        Přihláška
                    </Link>
                </div>
                {/* <div className="navbar__menu-line-container">
                    {" "}
                    <hr className="navbar__menu-line"></hr>
                </div> */}
                <div className="navbar__menu-contact-info">
                    <h3 className="navbar__menu-contact-info-title">
                        Kontaktujte nás!
                    </h3>
                    <h4 className="navbar__menu-info">
                        <a
                            href="mailto:havranizatoka@gmail.com"
                            alt="Odkaz na Email"
                            target="_blank"
                            rel="noopener"
                            onClick={() => handleMenu()}
                        >
                            <Sms
                                //size="24"
                                className="navbar__menu-icon navbar__menu-icon--envelope"
                            />
                            havranizatoka@gmail.com
                        </a>
                    </h4>
                    <h4 className="navbar__menu-info">
                        <a
                            href="tel:736507501"
                            alt="Odkaz na telefoní číslo"
                            target="_blank"
                            rel="noopener"
                            onClick={() => handleMenu()}
                        >
                            <CallCalling
                                //size="24"
                                className="navbar__menu-icon navbar__menu-icon--phone"
                            />
                            736 507 501
                        </a>
                    </h4>
                    <h4 className="navbar__menu-info">
                        <a
                            href="https://www.facebook.com/people/T%C3%A1bor-na-Havran%C3%AD-z%C3%A1toce/100064688320860/"
                            alt="Odkaz na Facebook"
                            target="_blank"
                            rel="noopener"
                            onClick={() => handleMenu()}
                        >
                            <Facebook
                                //size="24"
                                className="navbar__menu-icon navbar__menu-icon--facebook"
                            />
                        </a>
                    </h4>
                </div>
            </div>
        </div>
    );
};
export default MobileMenu;
