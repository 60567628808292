export const videos = [
    {
        year: "2021",
        src: "https://www.youtube.com/embed/aXWJ_JGgC0g",
        title: "Táborové video 2021",
    },
    {
        year: "2020",
        src: "https://www.youtube.com/embed/VATxeKi_-TU",
        title: "Táborové video 2020",
    },
    {
        year: "2019",
        src: "https://www.youtube.com/embed/ai2h74Gs8vw",
        title: "Táborové video 2019",
    },
    {
        year: "2018",
        src: "https://www.youtube.com/embed/Vkaq2qAbOLY",
        title: "Táborové video 2018",
    },
];
