import React, { useEffect } from "react";
import HomePage from "./components/pages/home-page/HomePage";
import "./App.scss";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    Navigate,
} from "react-router-dom";
import Layout from "./components/Layout";
import GalleryPage from "./components/pages/gallery-page/GalleryPage";
// import AboutUsPage from "./components/pages/about-us/AboutUsPage";
import ApplicationPage from "./components/pages/application/ApplicationPage";
import ForParentsPage from "./components/pages/for-parents/ForParentsPage";
import { PreschoolPage } from "./components/pages/preschool-page/PreschoolPage";
export const App = () => {
    return (
        <div className="App">
            <Router basename={process.env.PUBLIC_URL}>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route
                            path="/tabor.php"
                            element={<Navigate to="/" />}
                        />
                        <Route
                            path="/prihlaska.html"
                            element={<Navigate to="/" />}
                        />
                        <Route path="/galerie" element={<GalleryPage />} />
                        {/* <Route path="/o-nas" element={<AboutUsPage />} /> */}
                        <Route
                            path="/prihlaska"
                            element={<ApplicationPage />}
                        />
                        <Route
                            path="/pro-rodice"
                            element={<ForParentsPage />}
                        />
                        <Route
                            path="/tabor-pro-predskolaky"
                            element={<PreschoolPage />}
                        />
                    </Route>
                </Routes>
            </Router>
        </div>
    );
};
export default App;

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
