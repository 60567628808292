import { useState } from "react";

export const useSwipe = (leftSwipeFunction, rightSwipeFunction) => {
    const [touchPosition, setTouchPosition] = useState(null);
    const [isLocked, setIsLocked] = useState(false);

    const handleTouchStart = (e) => {
        setIsLocked(false);
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    };

    const handleSwipe = (e) => {
        const touchDown = touchPosition;
        if (touchDown === null) {
            return;
        }
        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (isLocked === false) {
            if (diff > 50) {
                setIsLocked(true);
                rightSwipeFunction();
            } else if (diff < -50) {
                setIsLocked(true);
                leftSwipeFunction();
            }
        }
        //console.log(diff);
    };

    return [handleTouchStart, handleSwipe];
};

export default useSwipe;
