import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { CallCalling, Sms, Map, Facebook } from "iconsax-react";

export const Footer = () => {
    return (
        <div className="footer">
            <iframe
                className="footer__map"
                style={{ border: "none" }}
                src="https://frame.mapy.cz/s/najofovalu"
                width="100%"
                frameBorder="0"
                center="49.7461825, 13.0012639"
            ></iframe>
            <div className="footer-container">
                <div className="footer__info">
                    <div className="footer__logo-container">
                        <img className="footer__logo"
                                src={require("../images/SPV_logo.png")}
                                alt="Děti u táborového ohně"
                        />
                        {/* <img
                            className="footer__logo"
                            src={require("../images/Logo_v2.png")}
                            alt="Děti u táborového ohně"
                        /> */}
                    </div>
                    {/* <div className="footer__logo-container">
                        
                    </div> */}
                    <div className="footer-navigation">
                        <h2 className="footer-navigation__title">Navigace</h2>
                        <ul className="footer-navigation__list">
                            <li className="footer-navigation__item">
                                <Link
                                    className="footer-navigation__link"
                                    to="/"
                                >
                                    Domů
                                </Link>
                            </li>
                            <li className="footer-navigation__item">
                                <Link
                                    className="footer-navigation__link"
                                    to="/pro-rodice"
                                >
                                    Pro rodiče
                                </Link>
                            </li>
                            <li className="footer-navigation__item">
                                <Link
                                    className="footer-navigation__link"
                                    to="/tabor-pro-predskolaky"
                                >
                                    Tábor pro předškoláky
                                </Link>
                            </li>
                            <li className="footer-navigation__item">
                                <Link
                                    className="footer-navigation__link"
                                    to="/galerie"
                                >
                                    Galerie
                                </Link>
                            </li>
                            <li className="footer-navigation__item">
                                <Link
                                    className="footer-navigation__link"
                                    to="/prihlaska"
                                >
                                    Přihláška
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-contact-info">
                        <h2 className="footer-contact-info__title">
                            Kontaktní informace
                        </h2>
                        <li className="footer-contact-info__item footer-contact-info__item--address">
                            {/* <div className="footer-contact-info__item"> */}
                            <Map
                                //size="24"
                                className="footer-contact-info__icon footer-contact-info__icon--map"
                            />
                            <b className="footer-contact-info__note">
                                Adresa:&nbsp;
                            </b>
                            <p className="footer-contact-info__address-text">
                                LT Osada Na Havraní zátoce, KČT Komárov, Pošta
                                Stříbro 349 01
                            </p>
                        </li>
                        <li className="footer-contact-info__item">
                            <a
                                href="mailto:havranizatoka@gmail.com"
                                alt="Odkaz na Email"
                                target="_blank"
                                rel="noopener"
                            >
                                <Sms className="footer-contact-info__icon" />
                                <b className="footer-contact-info__note">
                                    Email:&nbsp;
                                </b>
                                havranizatoka@gmail.com
                            </a>
                        </li>
                        <li className="footer-contact-info__item">
                            <a
                                href="tel:736507501"
                                alt="Odkaz na telefoní číslo"
                                target="_blank"
                                rel="noopener"
                            >
                                <CallCalling
                                    //size="24"
                                    className="footer-contact-info__icon"
                                />
                                <b className="footer-contact-info__note">
                                    Telefonní číslo:&nbsp;
                                </b>
                                736 507 501
                            </a>
                        </li>
                        <li className="footer-contact-info__item">
                            <a
                                href="https://www.facebook.com/people/T%C3%A1bor-na-Havran%C3%AD-z%C3%A1toce/100064688320860/"
                                alt="Odkaz na Facebook"
                                target="_blank"
                                rel="noopener"
                            >
                                <Facebook
                                    //size="24"
                                    className="footer-contact-info__icon"
                                />
                                <p className="footer-contact-info__note">
                                    <b>Facebook:&nbsp;</b>
                                    Osada Na Havraní zátoce
                                </p>
                            </a>
                        </li>
                    </div>
                </div>
                <div className="copyright-and-author">
                    <p className="copyright-and-author__text">
                        © 2023 Tomáš Ekert, Osada Na Havraní zátoce
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
