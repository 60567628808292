import React from "react";
import Navbar from "./nav/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";

export const Layout = () => {
    return (
        <div className="layout">
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    );
};

export default Layout;
