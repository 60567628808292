import React, { useEffect } from "react";
import "./home-page.scss";
import fireplaceIcon from "../../icons/fireplace.svg";
import backpackIcon from "../../icons/backpack.svg";
import ballIcon from "../../icons/ball.svg";
import guitarIcon from "../../icons/guitar.svg";
import taborv2 from "../../images/Tabor_v2.jpg";
import nocniHra from "../../images/Nocni_hra.jpg";
import detiVeStanu from "../../images/Deti_ve_stanu.jpg";
import jidelnaVNoci from "../../images/Jidelna_v_noci.jpg";
import jidelnaZNamesticka from "../../images/Jidelna_z_namesticka.jpg";
import detiUTaboraku from "../../images/Deti_u_taboraku.jpg";
import DetiVJidelne from "../../images/Deti_v_jidelne.jpg";

import OptimizedImage from "../../optimized-image/OptimizedImage";
const HomePage = () => {
    useEffect(() => {
        document.title = "Letní dětský tábor Osada Na Havraní zátoce 2024";
    }, []);
    return (
        <div className="home-page">
            <main className="main-section">
                <img
                    className="image image--main"
                    src={taborv2}
                    loading="lazy"
                    alt="Tábor"
                    decoding="async"
                    fetchpriority="high"
                />
                <div className="main-section__title-container">
                    <h1 className="main-section__title">
                        Osada <br />
                        Na Havraní zátoce
                    </h1>
                    <h2 className="main-section__title-description">
                        letní dětský tábor
                    </h2>
                    <div className="main-section__date-container">
                        <h2 className="main-section__date">
                            28. 7. - 11. 8. 2024
                        </h2>
                        <button className="main-section__application-button">
                            <a href="/prihlaska">PŘIHLÁŠKA</a>
                        </button>
                    </div>
                    <h3 className="main-section__spots-left">Cena: <strong>5.200 Kč</strong></h3>
                    {/* <h3 className="main-section__spots-left">
                        Zbývá posledních{" "}
                        <span className="main-section__spots-left-number">
                            6
                        </span>{" "}
                        volných míst.
                    </h3> */}
                </div>
            </main>
            <section className="home-page-about-us-section">
                <div className="home-page-about-us">
                    <h2 className="home-page-about-us__title section-title">
                        Něco málo o nás
                    </h2>
                    <p className="home-page-about-us__text">
                        Osada Na Havraní zátoce je letní tábor, na kterém si děti zahrají spoustu her v přírodě, poznají nové
                        kamarády a zažijí jedinečnou atmosféru tábora. Naše
                        základna se nachází v údolí řeky Úhlavky mezi městy
                        Stříbro a Kladruby. Tábor pořádá parta vedoucích z
                        Odboru SPV Lysá. S organizací tábora máme téměř <nobr><b>30 let</b></nobr> zkušeností a největší odměnou jsou pro nás úsměvy
                        dětí, které se k nám každý rok rády vrací
                        {" :-)"}.
                    </p>
                </div>
                <div className="home-page-about-us__second-container">
                    <h2 className="home-page-about-us__title section-title">
                        Proč jet s námi?
                    </h2>
                    <ul className="home-page-about-us__list">
                        <li>
                            Promakaná celotáborová hra se zábavnými scénkami.
                        </li>
                        <li>
                            Spousta zajímavých her a aktivit, které si děti
                            mohou vyzkoušet.
                        </li>
                        <li>
                            Parta zkušených vedoucích, kteří si táborem jako
                            děti sami prošli.
                        </li>
                        <li>14 dní v přírodě plných zábavy a poznání.</li>
                        <li>
                            Děti si odvezou spoustu nových dovedností a zážitků.
                        </li>
                        <li>Kamarádi na celý život.</li>
                    </ul>
                </div>
                <button className="home-page-about-us__button button">
                    <a href="/pro-rodice">Více informací</a>
                </button>
            </section>

            <section className="program-section">
                <h2 className="program__title section-title">
                    Na co se těšit{" "}
                </h2>
                <div className="program">
                    <div className="program__item">
                        <img
                            src={backpackIcon}
                            alt="Ikona baťohu"
                            className="program__icon"
                        />
                        <h3 className="program__mini-title">
                            Turistické výlety <br />
                            po okolí tábora
                        </h3>
                        <p className="program__description">
                            V průběhu tábora se děti mohou těšit na celodenní
                            výlet se spoustou her, na menší půldenní výlety po
                            okolních zajímavostech i na přespání pod širákem.
                        </p>
                    </div>
                    <div className="program__item">
                        <img
                            src={ballIcon}
                            alt="Ikona míče"
                            className="program__icon"
                        />
                        <h3 className="program__mini-title">
                            Individuální i <br />
                            oddílové hry
                        </h3>
                        <p className="program__description">
                            Na táboře budeme hrát spoustu zajímavých her, které
                            prověří jak individuální dovednosti, tak týmového
                            ducha dětí.
                        </p>
                    </div>
                    <div className="program__item">
                        <img
                            src={fireplaceIcon}
                            alt="Ikona ohniště"
                            className="program__icon"
                        />
                        <h3 className="program__mini-title">
                            Celotáborová
                            <br />
                            hra
                        </h3>
                        <p className="program__description">
                            Celých 14 dní nás bude provázet propracovaná
                            táborová hra, rozdělená do etap, ve které mezi sebou
                            budou oddíly soupeřit.
                        </p>
                    </div>
                    <div className="program__item">
                        <img
                            src={guitarIcon}
                            alt="Ikona kytary"
                            className="program__icon program__icon--guitar"
                        />
                        <h3 className="program__mini-title">
                            Odpočinkové <br />
                            aktivity
                        </h3>
                        <p className="program__description">
                            Dále máme pro děti připraveno mnoho odpočinkových
                            činností, mezi které patří například poslech kytary.
                        </p>
                    </div>
                </div>
            </section>
            <section className="gallery-section">
                <h2 className="gallery__title section-title">Galerie</h2>
                <div className="gallery">
                    <div className="gallery__image-container">
                        <OptimizedImage src={nocniHra} />
                    </div>

                    <div className="gallery__image-container">
                        <OptimizedImage src={detiVeStanu} />
                    </div>
                    <div className="gallery__image-container">
                        <OptimizedImage src={jidelnaVNoci} />
                    </div>
                    <div className="gallery__image-container">
                        <OptimizedImage src={jidelnaZNamesticka} />
                    </div>
                    <div className="gallery__image-container">
                        <OptimizedImage src={detiUTaboraku} />
                    </div>
                    <div className="gallery__image-container">
                        <OptimizedImage src={DetiVJidelne} />
                    </div>
                </div>
                <button className="gallery__button button">
                    <a href="/galerie">Přejít do galerie</a>
                </button>
            </section>
        </div>
    );
};
export default HomePage;
